<template>
  <section class="hosts-overview">
    <Loader :visible="isLoading" />
    <div v-for="host in hostsList" :key="host.id" class="host-element row">
      <div class="col-1 pr-0 host-element__id">id: {{ host.id }}</div>
      <div class="col">
        <div class="host-element__heading">
          <div class="row">
            <div class="host-element__name col-5">
              <a
                class="host-element__link mb-1"
                :href="`//${host.url}`"
                target="_blank"
              >
                {{ host.url }}
              </a>
              <span
                :class="{
                  'host-element__status--active': ['active', 'new'].includes(host.status),
                  'host-element__status--awaiting': host.status === 'awaiting'
                }"
                class="host-element__status mb-1"
              >
                {{ getStatus(host.status) }}
              </span>
              <div class="host-element__details">
                <template v-if="host.source_type">
                  <div class="mb-1">{{ $t('main.sourceType') }}: {{ hostInfo.source_type[host.source_type] }}</div>
                </template>
                <template v-if="host.environment">
                  <div class="mb-1">{{ $t('main.environment') }}: {{ hostInfo.environment[host.environment] }}</div>
                </template>
                <template v-if="host.placement">
                  <div class="mb-1">{{ $t('main.placementType') }}: {{ hostInfo.placement[host.placement] }}</div>
                </template>
                <template v-if="host.device_type">
                  <div class="mb-1">Smart TV: {{ hostInfo.device_type[host.device_type] }}</div>
                </template>
              </div>
            </div>
            <div class="host-element__fin-checkbox col-6">
              <Checkbox
                v-if="!hideEula"
                :val.sync="host.eula_accepted"
                v-on:update:val="updateElua(host)"
              >
                {{ $t("main.hostEula") }}
              </Checkbox>
            </div>
          </div>
        </div>
        <div class="host-element__controls container">
          <div class="row justify-content-between">
            <div class="host-element__controls-group">
              <CustomButton
                :label="$t('main.getCode')"
                type="common"
                icon="code"
                @click="openModal(host.id, host.url, host)"
              />
            </div>
            <div class="host-element__controls-group"></div>
          </div>
        </div>
      </div>
    </div>
    <el-pagination
      v-show="total > 0"
      background
      class="px-0"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      :page-size="listQuery.limit"
      :page-sizes="pageSizes"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <div v-if="!hostsList.length" class="hosts-overview--empty">
      {{ $t("main.emptyHostList") }}
    </div>
    <div class="hosts-overview__add-button-wrapper">
      <CustomButton
        :label="$t('main.addHost')"
        icon="add"
        @click="$store.commit('hostModalShown', true)"
      />
    </div>
    <!-- <Modal :shown.sync="isCodeModalShown">
      <h3 slot="header">{{ $t("main.embedCode") }}</h3>
      <div slot="body">
        <div v-for="cb in codes" :key="cb.name">
          <h4>{{ cb.name }}</h4>
          <p>{{ cb.text }}</p>
          <CodeBlock :code="cb.code" :exampleLink="cb.example" />
        </div>
      </div>
      <div slot="footer" />
    </Modal> -->
    <el-dialog
      title="Insertion codes"
      :visible.sync="isCodeModalShown"
      fullscreen
      @opened="setLoading"
    >
      <el-collapse v-model="activeCodeName" class="custom-collapse" accordion>
        <el-collapse-item
          v-for="{ label, key, description } in codesCollapseList"
          :name="key"
          :key="key"
          class="code-tab"
        >
          <template slot="title">
            <el-tooltip
              :content="description"
              popper-class="popper-max-width"
              placement="top-start"
              effect="light"
            >
              <i class="el-icon-info" />
            </el-tooltip>
            {{ label }}
          </template>
          <div
            v-loading="isFrameLoading"
            element-loading-custom-class="custom-loading"
          >
            <iframe
              ref="frameRef"
              :src="`${domain}/show-case/${key}/${currentHostId}`"
              class="frame-codes"
              frameborder="0"
              width="100%"
              height="850"
              allow="clipboard-read; clipboard-write"
            />
          </div>
        </el-collapse-item>
      </el-collapse>
      <!-- <div
        v-if="isYandexHB"
        class="banner-code__example"
      >
        <p>
          Пример запроса к монетизатору UMG.team в Yandex HB и настроенными
          параметрами, где "placementId" - идентификатор площадки в системе UMG.
        </p>
        <q>
          <pre> {{ codeExample }} </pre>
        </q>
      </div> -->
    </el-dialog>
  </section>
</template>

<script>
import CustomButton from '@/components/controls/CustomButton.vue';
import Checkbox from '@/components/decorative/Checkbox.vue';
import Loader from '@/components/decorative/Loader.vue';
// import CodeBlock from '@/components/decorative/CodeBlock.vue';
// import Modal from '@/components/Modal.vue';
// коды вставки
// import common from '@/strings/common';
// import commonAndDelete from '@/strings/commonAndDelete';
// import contentRoll from '@/strings/contentRoll';
// import flyRoll from '@/strings/flyRoll';
// import overRoll from '@/strings/overRoll';

export default {
  name: 'HostsOverview',
  components: {
    CustomButton,
    // Modal,
    // CodeBlock,
    Checkbox,
    Loader,
  },
  data() {
    return {
      currentHost: null,
      activeCodeName: '',
      currentHostId: 0,
      currentHostName: '',
      isCodeModalShown: false,
      isLoading: false,
      isFrameLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
      },
      total: 0,
      pageSizes: [10, 20, 30, 50, 100],
      hostInfo: {
        source_type: {
          direct: `${this.$t('main.embedCode')} / VAST`,
          yandex_hb: 'Yandex Header Bidding',
        },
        environment: {
          web: this.$t('main.webSite'),
          app: this.$t('main.application'),
        },
        placement: {
          instream: 'Instream video',
          outstream: 'Outstream video',
          banner: 'Banner',
        },
        device_type: {
          smart_tv: 'On',
          auto: 'Off',
        },
      },
    };
  },
  computed: {
    isYandexHB() {
      return this.currentHost?.source_type === 'yandex_hb';
    },
    codeExample() {
      return `curl --location 'http://a.utraff.com/yandex?host=${this.currentHostId}'
      --header 'Accept-Encoding: gzip'
      --header 'User-Agent:  Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36'
      --header 'Host: a.utraff.com'
      --header 'Origin: http://showcase.watchfeed.tv'
      --header 'Content-Type: application/json'
      --data '{
          "places": [
          {
              "id": "adfox_1481556385598437",
              "placementId": "${this.currentHostId}",
              "codeType": "${this.currentHost?.placement}"
          }],
          "settings":
          {
              "currency": "RUB",
              "windowSize":
              {
                  "width": 240,
                  "height": 400
              }
          }
        }`;
    },
    hideEula() {
      return !!process.env.VUE_APP_REMOVE_EULA;
    },
    hostsList() {
      return this.$store.getters.getHostsReverse;
    },
    // codes() {
    //   return [
    //     {
    //       ...common,
    //       code: common.code.replaceAll('{id}', this.currentHostId),
    //       name: this.$t('common.showcase'),
    //       text: this.$t('main.codeTextInfo1'),
    //     },
    //     {
    //       ...commonAndDelete,
    //       code: commonAndDelete.code.replaceAll('{id}', this.currentHostId),
    //       name: this.$t('common.showcaseWithDeletePlayer'),
    //       text: this.$t('main.codeTextInfo2'),
    //     },
    //     {
    //       ...contentRoll,
    //       code: contentRoll.code.replaceAll('{id}', this.currentHostId),
    //       text: this.$t('main.codeTextInfo3'),
    //     },
    //     {
    //       ...overRoll,
    //       code: overRoll.code.replaceAll('{id}', this.currentHostId),
    //       text: this.$t('main.codeTextInfo4'),
    //     },
    //     {
    //       ...flyRoll,
    //       code: flyRoll.code.replaceAll('{id}', this.currentHostId),
    //     },
    //   ];
    // },
    domain() {
      return process.env.VUE_APP_URL_SHOWCASE;
    },
    codesCollapseList() {
      if (this.currentHost?.placement === 'banner') {
        return [
          {
            key: 'banner',
            label: 'Banner',
            description: this.$t('main.descriptionBanner'),
          },
        ];
      }

      return [
        {
          key: 'embed',
          label: 'Content roll',
          description: this.$t('main.descriptionContentRoll'),
        },
        {
          key: 'sticky',
          label: 'Sticky roll',
          description: this.$t('main.descriptionStickyRoll'),
        },
        {
          key: 'flying',
          label: 'Flying roll',
          description: this.$t('main.descriptionFlyingRoll'),
        },
        {
          key: 'overroll',
          label: 'Over roll',
          description: this.$t('main.descriptionOverRoll'),
        },
      ];
    },
  },
  created() {
    this.getHosts();
  },
  methods: {
    getHosts() {
      this.isLoading = true;
      this.$store.dispatch('getHosts', this.listQuery)
        .then((res) => {
          this.total = res.total;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleCurrentChange(value) {
      this.listQuery.page = value;
      this.getHosts();
      window.scrollTo(0, 0);
    },
    handleSizeChange(value) {
      this.listQuery.limit = value;
      this.getHosts();
      window.scrollTo(0, 0);
    },
    setLoading() {
      const loading = () => {
        this.isFrameLoading = false;
      };

      if (Array.isArray(this.$refs.frameRef)) {
        this.$refs.frameRef.forEach((el) => {
          // eslint-disable-next-line no-param-reassign
          el.onload = loading;
        });
      } else {
        this.$refs.frameRef.onload = loading;
      }
    },
    getStatus(status) {
      switch (status) {
        case 'active':
          return this.$t('common.active');
        case 'awaiting':
          return this.$t('common.awaitingModeration');
        case 'new':
          return this.$t('common.new');

        default:
          return status;
      }
    },
    openModal(id, url, host) {
      this.currentHost = host;
      this.currentHostId = id;
      this.currentHostName = url;
      this.isCodeModalShown = true;
    },
    updateElua(host) {
      this.isLoading = true;

      this.$store
        .dispatch('setElua', host)
        .then(() => {
          // this.$log.success(resp);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/variables";

.banner-code__example {
  background-color: #000;
  color: #fff;
  padding: 1em 2em;
  pre {
    overflow-x: auto;
    margin-top: 1em;
    background-color: rgba(#fff, 0.1);
    padding: 1.5em 0.5em;
  }
}

.hosts-overview {
  position: relative;

  &__add-button-wrapper {
    padding: 20px 0;
    text-align: center;
  }

  &--empty {
    padding: 40px;
    color: $c-text-dimmed;
    text-align: center;
    text-transform: uppercase;
  }
}

.host-element {
  padding: 30px 0;

  &__id {
    color: $c-text-dimmed;
  }

  &__heading {
    padding-bottom: 15px;
  }

  &__link {
    display: block;
  }

  &__status {
    position: relative;
    display: block;
    padding: 4px 4px 2px 15px;

    &:before {
      $sz: 7px;
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: calc(50% - #{$sz / 2});
      width: $sz;
      height: $sz;
      border-radius: 100px;
      background-color: #888;
    }

    &--active {
      &:before {
        background-color: $c-success;
      }
    }

    &--awaiting {
      &:before {
        background-color: $c-warning;
      }
    }

    &--disabled {
      &:before {
        background-color: $c-danger;
      }
    }
  }

  & + & {
    border-top: 1px solid #{$c-border-extra-light}48;
  }
}

.code-tab {
  .el-collapse-item__header {
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.2s linear;
    &:hover {
      background-color: rgba(112, 172, 192, 0.2);
    }
  }
  .el-icon-info {
    margin-right: 8px;
    color: #70acc0;
  }
}

.custom-loading .el-loading-spinner {
  top: 5%;
}
</style>
